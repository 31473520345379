// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-bienvenido-jsx": () => import("./../../../src/pages/bienvenido.jsx" /* webpackChunkName: "component---src-pages-bienvenido-jsx" */),
  "component---src-pages-contrato-jsx": () => import("./../../../src/pages/contrato.jsx" /* webpackChunkName: "component---src-pages-contrato-jsx" */),
  "component---src-pages-encuesta-jsx": () => import("./../../../src/pages/encuesta.jsx" /* webpackChunkName: "component---src-pages-encuesta-jsx" */),
  "component---src-pages-formulario-jsx": () => import("./../../../src/pages/formulario.jsx" /* webpackChunkName: "component---src-pages-formulario-jsx" */),
  "component---src-pages-guardar-contacto-jsx": () => import("./../../../src/pages/guardar-contacto.jsx" /* webpackChunkName: "component---src-pages-guardar-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-metodos-de-pago-jsx": () => import("./../../../src/pages/metodos-de-pago.jsx" /* webpackChunkName: "component---src-pages-metodos-de-pago-jsx" */),
  "component---src-pages-pagos-jsx": () => import("./../../../src/pages/pagos.jsx" /* webpackChunkName: "component---src-pages-pagos-jsx" */),
  "component---src-pages-planes-jsx": () => import("./../../../src/pages/planes.jsx" /* webpackChunkName: "component---src-pages-planes-jsx" */),
  "component---src-pages-politica-de-privacidad-jsx": () => import("./../../../src/pages/politica-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-jsx" */),
  "component---src-pages-salas-jsx": () => import("./../../../src/pages/salas.jsx" /* webpackChunkName: "component---src-pages-salas-jsx" */),
  "component---src-pages-whatsapp-jsx": () => import("./../../../src/pages/whatsapp.jsx" /* webpackChunkName: "component---src-pages-whatsapp-jsx" */),
  "component---src-templates-obituario-jsx": () => import("./../../../src/templates/obituario.jsx" /* webpackChunkName: "component---src-templates-obituario-jsx" */),
  "component---src-templates-obituarios-jsx": () => import("./../../../src/templates/obituarios.jsx" /* webpackChunkName: "component---src-templates-obituarios-jsx" */)
}

